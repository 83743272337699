<template>
    <modal v-if="show" size="large" class="outlined">
        <template #header>
            <h1 class="text-center w-100 text-uppercase font-weight-bold">
                <span v-if="activeForm === 'password'">Forgot your password?</span>
                <span v-else-if="activeForm === 'register'">Create an Account</span>
                <span v-else-if="activeForm === 'registered'">Your account has been created!</span>
                <span v-else>Login</span>
            </h1>

            <a href="#" class="close-modal" @click.prevent="handleCloseModal">X</a>
        </template>

        <template #body>
            <alert :message="loginMessage" alertStyle="alert-warning"  v-if="loginMessage" />

            <form-reset-password-request v-if="activeForm === 'password'" @change-form="setActiveForm" @close="handleCloseModal" />
            <form-register v-else-if="activeForm === 'register'" @change-form="setActiveForm" @close="handleCloseModal" />
            <div v-else-if="activeForm === 'registered'">
                <button type="button" @click="handleCloseModal" class="btn btn-primary rounded-pill ml-2">Continue Shopping</button>
            </div>
            <form-login v-else @change-form="setActiveForm" @close="handleCloseModal" />
        </template>
    </modal>
</template>

<script>
import Modal from '@/components/Modal'
import FormResetPasswordRequest from '@/components/FormResetPasswordRequest';
import FormRegister from '@/components/FormRegister';
import FormLogin from '@/components/FormLogin';
import Alert from '@/components/Alert';

export default {
    components: {
        Modal,
        FormResetPasswordRequest,
        FormRegister,
        FormLogin,
        Alert,
    },

    props: ['show', 'loginMessage'],

    data() {
        return {
            activeForm: 'login',
        }
    },

    methods: {
        handleCloseModal () {
            this.$emit('close');
            this.activeForm = 'login';
        },

        setActiveForm (form) {
            this.activeForm = form;

            this.$router.push({ name: form });
        }
    },

    mounted () {
        if (this.$route.name === 'register') {
            this.activeForm = 'register';
        }
    },
}
</script>

<style>

</style>