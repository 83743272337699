<template>
    <div id="sidebar" class="sticky-top pb-2" v-clickOutside="handleClickOutside">
        <template v-if="isLargeScreen">
            <div>
                <div class="bg-custom-dark text-center">
                    <div class="row no-gutters">
                        <div v-for="(c, index) in availableCategoryFilters" :key="index" class="col category py-4" style="position:relative;" @mouseenter="toggleChildCategories(c.id, true)" @mouseleave="toggleChildCategories(c.id, false)">
                            <div 
                                role="button"
                                @click.stop="toggleChildCategories(c.id)"
                            >
                                <div class="text-custom-green font-weight-bold text-uppercase category-title-fresh">{{c.retail_subtitle}}</div>
                                <div class="category-header category-item">
                                    <div class="category-title dreamorp mt-1">{{c.retail_title }}</div>
                                    <div class="ml-2 mt-2">
                                        <i 
                                            class="fa-solid"
                                            :class="expandedCategories.has(c.id) ? 'fa-chevron-up' : 'fa-chevron-down'"
                                        ></i>
                                    </div>
                                </div>
                            </div>

                            <div class="cateogry-list px-4" style="position: absolute; top: 100%; left: 0px; width: 100%;">
                                <DropdownTransitionGroup>
                                    <template v-if="expandedCategories.has(c.id)">
                                        <div 
                                            class="child-category text-center bg-custom-dark text-white border-top border-custom-green border-3 text-uppercase font-weight-bold py-1 custom-shadow"
                                            style="z-index: 997;"
                                            role="button"
                                            v-for="(subcategory, subcategory_index) in c.subcategories" 
                                            :key="subcategory_index"
                                            @click="scrollToTop(); handleFilter(subcategory.slug); $store.commit('setExpandedCategories');"
                                        >
                                            {{ subcategory.name }}
                                        </div>
                                    </template>
                                </DropdownTransitionGroup>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <template
            v-else>
            <div class="text-center bg-custom-dark">
                <div class="pt-2 pb-3 px-3"
                    @click="showCategories = !showCategories; showChildrenCategories = false"
                >
                    <div class="text-custom-green text-uppercase poppins-bold">
                        {{ selectedCategory ? selectedCategory.retail_subtitle : 'Fresh from the farm' }}
                    </div>
                    <div class="text-white position-relative dreamorp">
                        <span style="font-size: xx-large;">
                            {{ selectedCategory ? selectedCategory.retail_title : 'Product Categories' }}
                        </span>
                        <i 
                            class="fa-solid"
                            :class="showCategories ? 'fa-chevron-up' : 'fa-chevron-down'"
                        ></i>
                    </div>
                </div>

                <div class="category-list" style="max-height: 0;">
                    <DropdownTransitionGroup >
                        <template v-if="showCategories">
                            <div v-for="c in availableCategoryFilters"  :key="c.id" 
                                class="child-category text-white border-top border-3 border-white bg-custom-dark border-dark dreamorp"
                                style="font-size: xx-large;"
                                @click="showChildrenCategories = true;
                                    $store.commit('setSelectedCategory', availableCategoryFilters.find(category => category.name === c.name));
                                    showCategories = false;
                                    $store.commit('setProductsHidden', true)"
                            >
                                {{c.retail_title}}
                            </div>
                        </template>
                    </DropdownTransitionGroup>
                </div>
            </div>
        </template>

        <div class="row no-gutters mx-2 mt-2 mb-0 pb-4 bg-white"
            style="max-height: 65vh; overflow: auto;"
            v-if="showChildrenCategories && selectedCategory && !isLargeScreen"
            @click.stop="showChildrenCategories = false; $store.commit('setProductsHidden', false)"
        >
            <div
                v-for="child in selectedCategory?.subcategories" :key="child.name"
                class="col-6 col-md-4 p-2"
            >
                <div
                    class="subcategory-selector"
                    @click="
                        scrollToTop();
                        handleFilter(child.slug);
                    "
                >
                    <img class="subcategory-image" :src="child.image ? child.image : '/images/categories/default.jpg'"/>

                    <div
                        class="subcategory-title poppins-bold w-100 px-4 pb-1 pt-2 text-right"
                    >
                        {{ child.name }}
                    </div>
                </div>
            </div>
        </div>

        <div class="search px-3 pt-2 pt-sm-3 text-right"
            v-if="!showCategories && !showChildrenCategories" 
        >
            <input type="text"             
                @click.stop = "scrollToTop"
                v-model.lazy="searchTerm"
                v-inputDelay="250"
                class="rounded-pill w-50 pr-2"
                placeholder="SEARCH ALL PRODUCTS"
            />
        </div>

        <!-- TODO: REMOVE? -->
        <!-- <a href="#" class="text-danger font-weight-bold d-none d-lg-block" @click.prevent="handleFilterReset">Reset Filters</a> -->
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import {screenSizeMixin } from '@/mixins/ScreenSizeMixin.js'
import DropdownTransitionGroup from "@/transitions/DropdownTransitionGroup.vue";

    export default {
        mixins: [screenSizeMixin],

        components: { DropdownTransitionGroup },

        data () {
            return {
                searchTerm: '',
                categoryFilter: '',
                subcategoryFilter: '',
                brandFilters: [],
                submenuOpen: null,

                //Mobile Cat Navigation
                showCategories: false ,
                showChildrenCategories: false
            }
        },

        mounted () {
            if (this.$route.name === 'category'
                && this.$route.params.slug
                && this.availableCategoryFilters.some(c => c.slug === this.$route.params.slug || c.subcategories.some(sc => sc.slug === this.$route.params.slug))) {
                this.categoryFilter = this.$route.params.slug;
            }
        },

        computed: {
            ...mapState({
                products: state => state.products.all,
                categories: state => state.categories,
                
                //Desktop Cat Navigation
                expandedCategories: state => state.categoryNav.expandedCategories,

                //Mobile Cat Navigation
                selectedCategory: state =>state.categoryNav.selectedCategory
            }),

            ...mapGetters([
                'availableCategoryFilters',
            ]),

            categoryFilters() {
                return JSON.parse(JSON.stringify(this.categories));
            },

            availableBrandFilters () {
                let brands = [];

                this.products.forEach(p => {
                    if (this.isVisible(p) && p.brand) {
                        let index = brands.findIndex(b => b.id === p.brand.id);

                        if (index === -1) {
                            brands.push({
                                id: p.brand.id,
                                name: p.brand.title,
                            });
                        }
                    }
                });

                brands.sort(function(a, b) {
                    if (a.name === b.name) {
                        return 0;
                    }

                    return a.name < b.name ? -1 : 1;
                });

                return brands;
            },

            hasPins () {
                let hasPins = false;

                this.products.forEach(p => {
                    if (p.pinned) {
                        hasPins = true;
                    }
                });

                return hasPins;
            },
        },


        methods: {
            handleClickOutside() {
                this.showChildrenCategories = false;
            },
            handleFilterReset () {
                this.searchTerm = '';
                this.categoryFilter = '';
                this.brandFilters = [];
            },

            isVisible (product) {
                let categoryIds = [];

                if(this.categoryFilter != '') {
                    //Find the ID of the category, and of it's parent, if applicable
                    this.categoryFilters.forEach((parent) => {
                        if (parent.slug === this.categoryFilter) {
                            categoryIds.push(parent.id);

                            parent.subcategories.forEach(sub => {
                                categoryIds.push(sub.id);
                            });

                        } else if (parent.subcategories.length) {
                            parent.subcategories.forEach((s) => {
                                if(s.slug === this.categoryFilter) {
                                    categoryIds.push(s.id);
                                }
                            })
                        }
                    });
                }

                return product.active &&
                    (this.categoryFilter === '') ||
                    (categoryIds.includes(product.category_id)) ||
                    (this.categoryFilter === 'special' && product.special) ||
                    (this.categoryFilter === 'locally_grown' && product.locally_grown) ||
                    (this.categoryFilter === 'pinned' && product.pinned);
            },

            unsetBrandFilters () {
                this.brandFilters.forEach((brand, index) => {
                    if (this.availableBrandFilters.findIndex(b => b.id === brand) === -1) {
                        this.brandFilters.splice(index, 1);
                    }
                });
            },

            handleFilter(filter) {
                this.searchTerm = '';
                this.categoryFilter = filter === 'all' ? '' : filter;
                // this.scrollToProductsTop(0);
            },

            isActive (filter) {
                return this.categoryFilter === filter;
            },

            isCanning (filter) {
                return filter === 'canning_items';
            },

            showSubcategories(filter) {
                return filter.slug === this.categoryFilter || filter.subcategories.some(i => i.slug === this.categoryFilter);
            },

            toggleSubmenu(filter) {
                if(this.submenuOpen === filter) this.submenuOpen = null;
                else this.submenuOpen = filter;
            },

            scrollToProductsTop(offset) {
                const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
                const headerHeight = document.getElementById('header').clientHeight;
                const sidebarHeight = document.getElementById('sidebar').clientHeight;
                const yOffset = vw >= 992 ? headerHeight : headerHeight + sidebarHeight;
                const productsContainer = window.document.getElementById('products');
                const y = productsContainer.getBoundingClientRect().top + window.pageYOffset - yOffset + offset;
                window.scrollTo(0, y);
            },

            toggleChildCategories(parentId, open = true) {
                let newExpandedCategories = new Set(JSON.parse(JSON.stringify([...this.expandedCategories])));

                this.expandedCategories.has(parentId) || !open ? 
                    newExpandedCategories.delete(parentId) :
                    newExpandedCategories.add(parentId)
                
                this.$store.commit('setExpandedCategories', newExpandedCategories);
            },
        },

        watch: {
            subcategoryFilter (newValue) {
                this.$store.commit('setSubcategoryFilter', newValue)
            },

            searchTerm (newValue, oldValue) {
                if (oldValue.length == 0 && newValue.length > 0) {
                    this.categoryFilter = '';
                    this.brandFilters = [];
                }
                this.$store.commit('setSearchTerm', newValue);
            },

            categoryFilter (newValue) {

                switch (newValue) {
                    case '':
                        this.$store.commit('setFoodFilters', []);
                        this.$store.commit('setPromoFilters', []);
                        break;

                    case 'special':
                    case 'new':
                    case 'organic':
                    case 'locally_grown':
                    case 'pinned':
                        this.unsetBrandFilters();
                        this.$store.commit('setPromoFilters', [newValue]);
                        this.$store.commit('setFoodFilters', []);
                        break;

                    default:
                        this.unsetBrandFilters();
                        this.$store.commit('setFoodFilters', [newValue]);
                        this.$store.commit('setPromoFilters', []);
                        break;
                }

                if (newValue) this.$router.push({ name: 'category', params: { slug: newValue }});
            },

            brandFilters (newValue) {
                this.$store.commit('setBrandFilters', [...newValue]);
            }
        }
    }
</script>

<style scoped>
    #sidebar {
        top:var(--navbar-height);
        margin-bottom: -72.5px;
        -webkit-user-select: none; /* Safari */        
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* IE10+/Edge */
        user-select: none; /* Standard */
    }

    .search input {
        background: #efefef;
        border: 1px #091601 solid;
        height: 38px;
        font-weight: bold;
        text-align: left;
        padding-left: 16px;
    }

    .search input::placeholder {
        color: #091601bb;
        padding-right: 16px;
    }

    .category:hover .category-item, .child-category:hover {
        color: #ced0cc !important;
    }

    .category-header {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        margin-left: 1rem;
    }

    .category-title {
        font-size: 3.75rem;
        font-weight: 300;
        line-height: 1.2;
        letter-spacing: 2px;
    }

    body.stickied .category-title {
        font-size: 2.5rem;
        font-weight: 300;
        line-height: 1;
        letter-spacing: 1px;
    }

    .subcategory-selector {
        cursor: pointer;
        width: 100%;
        border-radius: 20px;
        overflow: hidden;
        border: 0px solid #85C71E;
        /* border: 0px solid #091601; */
    }

    .subcategory-selector:hover, .subcategory-selector:focus, .subcategory-selector:active {
        opacity: 0.8;
    }

    .subcategory-title {
        border-radius: 0px;
        font-size: 25px;
        font-weight: 600;
        background-color: #85C71E;
        /* background-color: #091601; */
        color: white;
        white-space: nowrap;
    }

    .subcategory-image {
        height: 200px;
        width: 100%;
        object-fit: cover;
    }

    body.stickied .category-title-fresh {
        font-size: 0.875rem;
    }

    .cateogry-list .child-category:last-child
    {
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }

    @media screen and (max-width: 768px) {
        .search input {
            height: 30px;
            font-size: 16px;
            font-weight: bold;
        }

        .search input::placeholder {
            padding-right: 0px;
            font-size: small;
        }

        .subcategory-title {
            font-size: 15px;
        }

        .subcategory-image {
            height: 100px;
        }
    }
</style>
