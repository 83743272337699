<template>
    <modal v-if="show" @close="$emit('close')" size="larger" class="outlined">
        <template #header>
            <h1 class="text-center w-100 text-uppercase font-weight-bold">Contact Us</h1>

            <a href="#" class="close-modal" @click.prevent="$emit('close')">X</a>
        </template>

        <template #body>
            <div class="container">

                <div class="row">
                    <div class="col-md-4 text-center">
                        <div class="step">
                            <img src="/images/icons/location.svg" width="24px" height="24px" />
                        </div>

                        <div class="mb-0">
                            Follow Us on Social Media!

                            <div class="d-flex align-items-center justify-content-center my-1 mx-auto">
                                <a href="https://www.facebook.com/Produce-Express-105105928123651/" target="_blank">
                                    <i class="fa-brands fa-square-facebook h2 text-custom-green mr-2"></i>
                                </a>

                                <a href="https://www.instagram.com/produce.express" target="_blank">
                                    <i class="fa-brands fa-instagram h2 text-custom-green"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 text-center">
                        <div class="step">
                            <img src="/images/icons/phone.svg" width="24px" height="24px" />
                        </div>

                        <p class="mb-0">
                            Give Us a Call!
                            <br> <a href="tel:+15195399333" class="font-weight-bold text-custom-green">(519) 539-9333</a>
                        </p>
                    </div>

                    <div class="col-md-4 text-center">
                        <div class="step">
                            <img src="/images/icons/envelop.svg" width="24px" height="24px" />
                        </div>
                        <p class="mb-0">
                            Send Us an Email!
                            <br> <a href="mailto:sales@fresherproduce.ca" class="font-weight-bold text-custom-green">sales@fresherproduce.ca</a>
                        </p>
                    </div>

                </div>

                <div class="row">
                    <div class="col-md-12 text-center">
                        <div class="step">
                            <img src="/images/icons/internet.svg" width="24px" height="24px" />
                        </div>

                        <p class="mb-0">
                            Find out more about us by heading to our company information website
                            <br> <a class="font-weight-bold text-custom-green" href="https://produceexpress.ca" target="_blank">produceexpress.ca</a>
                        </p>
                    </div>
                </div>
            </div>
        </template>

        <template #footer></template>
    </modal>
</template>

<script>
    import Modal from '@/components/Modal'

    export default {
        components: {
            Modal,
        },

        props: ['show'],
    }
</script>

<style scoped>
    .close-modal {
        color: #FF5047;
        line-height: 1;
    }

    .close-modal:hover {
        text-decoration: none;
    }

    h1 {
        font-size: 2rem
    }

    .col-md-4 {
        margin-bottom: 1.5rem;
    }

    .col-md-4:last-child {
        margin-bottom: 0;
    }

    @media (min-width: 768px) {
        .col-md-4 {
            margin-bottom: 0;
        }
    }

    .step {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 0;
        font-size: 1.75rem;
        content: "";
        background: #FF5047;
        color: #fff;
        border-radius: 50%;
        width: 48px;
        height: 48px;
        flex: 0 0 auto;
        margin: 0 auto 1rem;
    }
</style>