<template>
    <modal v-if="show" @close="$emit('close')" size="large" class="outlined about">
        <template #header>
            <h1 class="text-center w-100 text-uppercase font-weight-bold">Delivery Check</h1>

            <a href="#" class="close-modal m-2" @click.prevent="$emit('close')">X</a>
        </template>

        <template #body>
            <div class="container">
                <h5>Enter your full address to find out if we deliver to you!</h5>
                <div class="input-group">
                    <input id="delivery-check-address" type="text" class="form-control border border-custom-green" ref="deliveryCheckAddress" v-model="deliveryCheckAddress" maxlength="200" @keyup.enter="handleDeliveryCheck">
                    <div class="input-group-append">
                        <button type="button" class="btn border-custom-green text-custom-green" @click.prevent="handleDeliveryCheck">Check</button>
                    </div>
                </div>
                <div class="delivery-check-results pt-4" v-if="result_status">
                    <h5 :class="resultStatusClass">{{ result_message }}</h5>
                </div>
            </div>
        </template>

        <template #footer>
        </template>
    </modal>
</template>

<script>
    import Modal from '@/components/Modal'

    export default {
        components: {
            Modal,
        },

        props: ['show'],

        data() {
                return {
                    deliveryCheckAddress: null,
                    result_status: null,
                    result_message: null,
                    token: null,
                }
            },

        methods: {
            handleDeliveryCheck() {
                this.result_status = "checking";
                this.result_message = "Checking your address. Please wait...";

                this.axios.post(process.env.VUE_APP_API_WHOLESALE + '/api/delivery_check', {"deliveryCheckAddress": this.deliveryCheckAddress}, { timeout: 2000 })
                    .then(response => {
                         this.result_status = response.data.result_status;
                         this.result_message = response.data.result_message;
                    })
                    .catch(err => {
                        if (err?.response?.status === 429) {
                            this.result_status = "too_many_requests";
                            this.result_message = "Too many requests. Please try again later";

                        }
                        else {
                            console.log(err);
                            this.result_status = "fail";
                            this.result_message = "Unable to check your address. Please try again later";
                        }
                    })
            },
        },
        computed: {

            resultStatusClass () {

                if (this.result_status == "checking") return 'text-dark blink_me'
                if (this.result_status == "delivery_notyet_available") return 'text-success'
                if (this.result_status == "delivery_available") return 'text-success'
                if (this.result_status == "address_incorrect") return 'text-danger'
                if (this.result_status == "fail") return 'text-danger'
                if (this.result_status == "too_many_requests") return 'text-danger'
                else return 'text-danger'
            },
        },

        watch: {
                show (newValue) {
                    if(newValue === true) {
                        this.$nextTick(() => {
                            this.$refs.deliveryCheckAddress.focus();
                        });
                    }
                    else {
                        // Clear form. (Closing)
                        this.deliveryCheckAddress = null;
                        this.result_status = null;
                        this.result_message = null;
                    }
                }
            }
        }
</script>

<style scoped>
    .close-modal {
        color: #FF5047;
        line-height: 1;
    }

    .close-modal:hover {
        text-decoration: none;
    }

    .col-md-6 {
        margin-bottom: 1rem;
    }

    .col-md-6:first-child {
        order: 1;
    }

    @media (min-width: 768px) {
        .col-md-6 {
            margin-bottom: 0;
        }


        .col-md-6:first-child {
            order: 0;
        }
    }

    .blink_me {
    animation: blinker 1s linear infinite;
    }

    @keyframes blinker {
    50% {
        opacity: 0.25;
    }
    }
</style>
