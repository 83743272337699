<template>
     <div id="thankyou" class="text-center mt-5">
            <h1 class="h2">Order Placed</h1>
            <p>
                Thank you for your order, we appreciate your business.<br>
                A confirmation/receipt email will be sent to {{ thankyou_email }}.
            </p>

            <p class="text-center">
                <router-link to="/" class="btn btn-secondary rounded-pill">Back to Catalog</router-link>
            </p>
        </div>
</template>

<script>
    export default {
        props: ['thankyou_email'],

        metaInfo () {
            return {
                title: 'Thank You',
                meta: [
                    { charset: 'utf-8' },
                    { vmid: 'robots', name: 'robots', content: 'noindex' }

                ]
            }
        },

        mounted () {
            if (!this.thankyou_email) {
                this.$router.replace('/');
            }
        }
    }
</script>

<style>

</style>