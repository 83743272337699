<template>
    <div>
        <div v-if="currentUser">
            <div class="row">
                <div class="col-md-6">
                    <credit-card-selector
                        v-model="token"
                        v-for="card in currentUser.vaults"
                        :key="card.id"
                        :card="card"
                        :value="String(card.id)"
                        class="payment-card payment-vault"
                        @remove-card="handleRemoveCard"
                    />

                    <credit-card-selector
                        v-model="token"
                        value=""
                        class="payment-card payment-default"
                        :class="{ 'payment-only': !hasCardsInVault }"
                    />
                </div>
            </div>

            <credit-card-expanded
                v-if="token === ''"
                :isShippingMethodComplete="isShippingMethodComplete"
                :isShippingAddressComplete="isShippingAddressComplete"
                :validate="validate"
                :is-order-complete="isOrderComplete"
                @credit-card-update="handleUpdateCardFields"
            />

            <p class="error my-3" v-text="errorMessage" v-if="errorMessage"></p>
        </div>

        <div v-else class="row">
            <div class="col-md">
                <credit-card
                    @credit-card-update="handleUpdateCardFields"
                    :errorMessage="errorMessage"
                    :isShippingMethodComplete="isShippingMethodComplete"
                    :isShippingAddressComplete="isShippingAddressComplete"
                    :validate="validate"
                    :use-vee-validate="false"
                    :is-order-complete="isOrderComplete"
                />
            </div>

            <div class="col-md-6 text_center_if_small" style="vertical-align: base">
                <img src="/images/we-accept.jpg" alt="We Accept Visa, Mastercard, Amex, and Discover" title="We Accept Visa, Mastercard, Amex, and Discover" width="164" height="38" />
            </div>
        </div>

        <modal v-if="cardToRemove" :closeable="true" @close="cardToRemove = null">
            <template #header>
                <h3>Remove Payment Method</h3>
            </template>

            <template #body>
                <p class="my-4">Are you sure you want to remove this payment method?</p>
            </template>

            <template #footer>
                <button type="button" class="btn btn-secondary rounded-pill" @click.prevent="removeCard">Ok</button>
                <button type="button" class="btn btn-outline-secondary rounded-pill" @click.prevent="cardToRemove = null;">Cancel</button>
            </template>
        </modal>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import CreditCard from '@/components/CreditCard';
import CreditCardExpanded from '@/components/CreditCardExpanded';
import CreditCardSelector from '@/components/CreditCardSelector';
import Modal from '@/components/Modal';
import { trackAddPaymentInfo } from "../dataLayer.js";

export default {
    props: ['errorMessage', 'isShippingMethodComplete', 'validate', 'isOrderComplete', 'isShippingAddressComplete'],

    components: {
        CreditCard,
        CreditCardExpanded,
        CreditCardSelector,
        Modal,
    },

    data() {
        return {
            token: '',
            cardToRemove: null,
        }
    },

    computed: {
        ...mapState({
            currentUser: state => state.user.currentUser,
        }),

        ...mapGetters([
            'productsInCart',
            'grandTotal',
        ]),

        defaultPaymentMethod () {
            if (this.hasCardsInVault) {
                return String(this.currentUser.vaults[0].id);
            }

            return '';
        },

        hasCardsInVault () {
            return this.currentUser?.vaults?.length > 0;
        }
    },

    methods: {
        handleUpdateCardFields (payload) {
            this.$emit('credit-card-update', payload);

            if (payload.cardnumber !== '' && payload.expiry !== '' && payload.cvd !== '') {
                trackAddPaymentInfo(this.productsInCart, this.grandTotal);
            }
        },

        handleRemoveCard (payload) {
            this.cardToRemove = payload;
        },

        removeCard () {
            if (!this.cardToRemove) {
                return;
            }

            const url = process.env.VUE_APP_API_WHOLESALE + '/api/vault/' + this.cardToRemove.id + '/remove';

            this.axios.post(url).then(response => {
                this.cardToRemove = null;

                this.$store.commit('setUserVault', response.data.vaults);
            });
        }
    },

    watch: {
        isOrderComplete (newValue) {
            if (newValue) {
                this.token = this.defaultPaymentMethod;
            }
        },

        token (newValue) {
            this.$emit('vault-token-update', { vault_id: newValue });
        }
    },

    mounted () {
        this.token = this.defaultPaymentMethod;
    }
}
</script>

<style scoped>
    .payment-card {
        border: 1px solid #ced4da;
        padding: 1rem;
    }

    .payment-card >>> label {
        line-height: 1.2;
        margin: 0 0.75rem;
        flex-grow: 1;
        cursor: pointer;
        font-size: 1rem;
    }

    .payment-card >>> input[type="radio"] {
        zoom: 1.25;
    }

    .payment-vault {
        border-bottom: none;
    }

    .payment-vault:first-child {
        border-radius: 0.25rem;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .payment-default {
        border-radius: 0.25rem;
    }

    .payment-default:not(.payment-only) {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .error {
        display: block;
        color: #cc0000;
    }
</style>
