export const screenSizeMixin = {
  data() {
    return {
      isLargeScreen: window.innerWidth > 1200,
    };
  },
  methods: {
    updateScreenSize() {
      this.isLargeScreen = window.innerWidth > 1200;
    },
  },
  created() {
    window.addEventListener('resize', this.updateScreenSize);
  },
  destroyed() {
    window.removeEventListener('resize', this.updateScreenSize);
  },
};
