<template>
    <div v-if="isSuccessful">
        <p>Check your inbox. We have emailed your password reset link!</p>

        <button type="button" @click="$emit('close')" class="btn btn-outline-secondary rounded-pill">Close</button>
    </div>

    <form action="#" @submit.prevent="handleSubmit" v-else>
        <p class="">Forgot your password? No problem. Just let us know your email address and we will email you a password reset link that will allow you to choose a new one.</p>

        <div class="form-group">
            <label for="">Email</label>
            <input type="text" v-model="form.email" class="form-control" ref="email" />
            <span v-if="errors.email" class="text-danger">{{ errors.email[0] }}</span>
        </div>

        <div class="d-flex justify-content-between align-items-center mt-5">
            <div>
                <input type="submit" class="btn btn-primary rounded-pill" value="Email Password Reset Link" />
                <button type="button" @click="$emit('change-form', 'login')" class="btn btn-outline-secondary rounded-pill ml-2">Back</button>
            </div>
        </div>
    </form>
</template>

<script>
export default {
    data() {
        return {
            form: {
                email: '',
            },

            errors: '',

            isSuccessful: false,
        }
    },

    methods: {
        handleSubmit () {
            this.$store.dispatch('requestPasswordReset', this.form)
                .then(() => {
                    this.isSuccessful = true;
                })
                .catch(error => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    }
                });
        }
    },

    mounted () {
        this.$refs.email.focus();
    }
}
</script>

<style>

</style>