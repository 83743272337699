<template>
    <div class="pagination">
        <template v-for="(link, key) in links">
            <div v-if="link.url === null" :key="key" class="mr-1 mb-1 px-3 py-2 text-sm border rounded-pill" :class="{ 'ml-auto': link.label === 'Next' }">
                <span v-html="link.label"></span>
            </div>

            <a v-else
                href="#"
                :key="key"
                :class="{ 'active': link.active, 'ml-auto': link.label === 'Next' }"
                class="mr-1 mb-1 px-3 py-2 text-sm border rounded-pill"
                @click.prevent="$emit('page-update', link.url)"
            >
                <span v-html="link.label"></span>
            </a>
        </template>
    </div>
</template>

<script>
export default {
  props: {
    links: Array,
  },
}
</script>

<style scoped>
.pagination {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.active {
    background: #85C71E;
    color: #fff;
}

a {
    line-height: 1.4;
    display: inline-block;
}

a:hover {
    text-decoration: underline;
}
</style>