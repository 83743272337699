<template>
    <a href="#" class="nav-link dropdown-toggle user-menu text-white" aria-expanded="false" 
        data-toggle="dropdown"
    >
        <i class="fa-solid fa-user mr-1 login-icon"></i>
        <span class="pl-1 users-name">Hello <span>{{ currentUser.first_name }}</span></span>

        <div class="dropdown-menu">
            <router-link class="dropdown-item text-white" :to="{ name: 'orderHistory' }">My Account</router-link>
            <a class="dropdown-item text-white" href="#" @click.prevent="handleLogout">Sign Out</a>
        </div>
    </a>
</template>

<script>
export default {
    props: ['currentUser'],

    methods: {
        handleLogout () {
            this.$store.dispatch('signOut').then(() => {
                window.location = '/';
            });
        },
    },

    mounted () {
        window.$('.user-menu').dropdown();
    }
}
</script>

<style scoped>
    .users-name {
        display: none;
    }

    .user-menu:hover .login-icon {
        color: var(--custom-green);
    }
    
    @media (min-width: 768px) {
        .users-name {
            display: inline-block;
        }
    }
</style>