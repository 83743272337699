<template>
  <div class="container content">
      <h1>Maintenance</h1>

      <p>We're currently down for maintenance, please try again later.</p>

      <button type="button" class="btn btn-primary rounded-pill" @click.prevent="handleRefresh">Refresh</button>
  </div>
</template>

<script>
export default {
    methods: {
        handleRefresh() {
            window.location = '/';
        }
    }
}
</script>

<style>

</style>