<template>
    <div id="modal-template">
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper" :class="size" @click.self="close()">
                    <div class="modal-container">
                        <a href="#" @click.prevent="$emit('close')" class="close-modal" v-show="closeable"><i class="fas fa-times-circle"></i></a>

                        <div class="modal-header">
                            <slot name="header"></slot>
                        </div>
                        <div class="modal-body">
                            <slot name="body"></slot>
                        </div>

                        <div class="modal-footer">
                            <slot name="footer"></slot>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
    props: ['showModal', 'size', 'closeable'],

    methods: {
        close() {
            this.$emit('close');
        }
    }
}
</script>

<style scope>
    .modal-mask {
        position: fixed;
        z-index: 998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: table;
        transition: opacity 0.3s ease;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
        z-index: 9998;
    }

    .modal-container {
        max-width: 600px;
        max-height: 95vh;
        overflow: auto;
        margin: 0px auto;
        padding: 20px 30px;
        background-color: #fff;
        border-radius: 2px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
        transition: all 0.3s ease;
        font-family: Helvetica, Arial, sans-serif;
    }

    .referral .modal-container, .howitworks .modal-container, .about .modal-container {
        padding: 0 !important;
    }

    .modal-header,
    .modal-body,
    .modal-footer {
        padding: 0;
    }

    .howitworks .modal-header,
    .howitworks .modal-body,
    .howitworks .modal-footer,
    .about .modal-header,
    .about .modal-body,
    .about .modal-footer {
        padding: 1rem;
    }

    .referral .modal-header, .howitworks .modal-header, .about .modal-header {
        background-color:white;
    }

    .referral-header {
        background: #85C91E;
        color: #F1283A;
        font-weight: 900;
    }

    @media (max-width:400px)
    {
        .referral-header {
            zoom: 0.9;
        }
    }

    .pricing .modal-header {
        align-items: center;
    }

    .modal-header a {
        color: #6c757d;
        margin-top: 0;
        font-size: 1.5rem;
    }

    .modal-header h3 {
        color: rgb(211, 7, 7);
        margin-top: 0;
    }

    .modal-header h1::after{
        display: block;
        content: '';
        border-bottom: 3px solid #FF5047;
        width: 60%;
        height: 1px;
        margin: 0.5rem auto;
    }

    .modal-header,
    .modal-footer {
        border: none;
    }

    .modal-header {
        position: sticky;
        top: 0;
        z-index: 10;
    }

    .modal-body ol,
    .modal-body ul {
        margin: 0 0 20px;
        padding-left: 24px;
    }

    .modal-body {
        font-size: 16px;
    }

    .modal-body p {
        line-height: 1.4;
    }

    /*
    * The following styles are auto-applied to elements with
    * transition="modal" when their visibility is toggled
    * by Vue.js.
    *
    * You can easily play with the modal transition by editing
    * these styles.
    */

    .modal-enter {
        opacity: 0;
    }

    .modal-leave-active {
        opacity: 0;
    }

    .modal-enter .modal-container,
    .modal-leave-active .modal-container {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }

    .modal-wrapper.large .modal-container {
        max-width: 800px;
    }

    .modal-wrapper.larger .modal-container {
        max-width: 950px;
    }

    .modal-wrapper.xlarge .modal-container {
        max-width: 1200px;
    }

    .modal-container {
        position: relative;
        padding: 1rem;
        opacity: 0.95;
    }

    .modal-body {
        max-height: 75vh;
    }

    @media (min-width: 1024px) {
        .modal-container {
            padding: 20px 30px;
        }
        .modal-body {
            max-height: 80vh;
        }
    }

    .close-modal {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 9999;
        font-size: 2rem;
        line-height: 1;
        font-weight: 600;
    }

    .outlined .modal-container {
        border-radius: 10px;
    }
</style>