const state = () => ({
    //Desktop Cat Navigation
    expandedCategories: new Set(),

    //Mobile Cat Navigation
    selectedCategory: null
});

const mutations = {
    setExpandedCategories(state, categories = new Set()) {
        state.expandedCategories = categories;
    },

    setSelectedCategory(state, selectedCategory = null) {
        state.selectedCategory = selectedCategory;
    }
};

const actions = {
    updateExpandedCategories({ commit }, categories = new Set()) {
        commit('setExpandedCategories', categories);
    }
};

export default {
    state,
    mutations,
    actions
};