<template>
    <form action="#" @submit.prevent="handleLogin">
        <p>Don't have an account?
            <a href="#" @click="$emit('change-form', 'register')" class="text-underline">Sign Up</a>
        </p>

        <alert :message="error" alertStyle="alert-danger" v-if="error" />

        <div class="form-group">
            <label for="">Email</label>
            <input type="email" v-model="form.email" class="form-control" ref="email" :disabled="!isCookiesEnabled" />
            <span v-if="validationErrors.email" class="text-danger">{{ validationErrors.email[0] }}</span>
            <span v-if="validationErrors.username" class="text-danger">{{ validationErrors.username[0] }}</span>
        </div>

        <div class="form-group">
            <label for="">Password</label>
            <input type="password" v-model="form.password" class="form-control" :disabled="!isCookiesEnabled" />
            <span v-if="validationErrors.password" class="text-danger">{{ validationErrors.password[0] }}</span>
        </div>

        <div class="mt-5">
            <p class="text-danger" v-if="!isCookiesEnabled">Cookies must be enabled to login.</p>

            <div class="d-flex justify-content-between align-items-center">
                <div>
                    <input type="submit" class="btn btn-primary rounded-pill" value="Login" :disabled="!isCookiesEnabled" />
                    <button type="button" @click="$emit('close')" class="btn btn-outline-secondary ml-2 rounded-pill">Cancel</button>
                </div>

                <div>
                    <a href="#" @click.prevent="$emit('change-form', 'password')">Forget your password?</a>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import Alert from '@/components/Alert';

export default {
    components: {
        Alert,
    },

    data() {
        return {
            form: {
                email: '',
                password: '',
            },

            error: '',
            validationErrors: '',

            isCookiesEnabled: null,

            isProcessing: false,
        }
    },

    methods: {
        handleLogin () {
            this.error = '';
            this.validationErrors = '';

            if (this.isProcessing) {
                return;
            }

            this.isProcessing = true;

            this.$store.dispatch('signIn', this.form)
                .then(() => {
                    // ! To clear the query params that trigger login modal after a successful sign in.
                    // ! Empty catch method is to prevent Sentry from causing a navigationDuplicated error. We're doing
                    // ! this intentionally to clear the query parameters.
                    this.$router.replace({ query: null }).catch(() => {});

                    this.$emit('close');
                })
                .catch(error => {
                    if (error?.response?.status === 422) {
                        this.validationErrors = error.response.data.errors;
                    } else {
                        this.error = 'Something went wrong, please try again.';
                    }
                })
                .finally(() => {
                    this.isProcessing = false;
                });
        },
    },

    created () {
        this.isCookiesEnabled = navigator.cookieEnabled;
    },

    mounted () {
        this.$refs.email.focus();
    }
}
</script>

<style>

</style>
