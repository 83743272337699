<template>
  <transition-group 
      name="dropdown" 
      @before-enter="beforeEnter" 
      @enter="enter" 
      @leave="leave"
  >
      <slot></slot>
  </transition-group>
</template>

<script>
export default {
  name: "DropdownTransitionGroup",

  methods: {
      beforeEnter(el) {
          el.style.height = "0px";
          el.style.opacity = "0";
      },
      enter(el, done) {
          el.style.transition = "height 0.3s ease, opacity 0.3s ease";
          
          // Calculate the total height including padding
          const paddingTop = parseFloat(window.getComputedStyle(el).paddingTop);
          const paddingBottom = parseFloat(window.getComputedStyle(el).paddingBottom);
          const totalHeight = el.scrollHeight + paddingTop + paddingBottom;
          
          el.style.height = totalHeight + "px";
          el.style.opacity = "1";
          
          setTimeout(done, 300);
      },
      leave(el, done) {
          el.style.transition = "height 0.2s ease, opacity 0.2s ease";
          el.style.height = "0px";
          el.style.opacity = "0";
          setTimeout(done, 200);
      }
  }
};
</script>