import axios from 'axios'
import Vue from 'vue'
import cookie from 'vue-cookies'
import Vuex from 'vuex'
import products from './modules/products'
import user from './modules/user'
import categoryNav from './modules/categoryNav'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
    strict: debug,

    state: {
        announcements: [],
        categories: [],
        showModalDeliveryTimes: false,
    },

    actions: {
        fetchAnnouncements({ commit }) {
            return axios
                .get(process.env.VUE_APP_API_WHOLESALE + '/api/announcements')
                .then(response => {
                    commit('setAnnouncements', response.data)
                })
        },


        fetchCategories({ commit }) {
            return axios
                .get(process.env.VUE_APP_API_WHOLESALE + '/api/categories')
                .then(response => {
                    commit('setCategories', response.data)
                })
        },

        setDeliveryTimesCounter({ commit }) {
            commit("setDeliveryTimesCounter");
        },

        setShowModalDeliveryTimes({ commit }, payload) {
            commit("setShowModalDeliveryTimes", payload);
        },
    },

    mutations: {
        setAnnouncements (state, payload) {
            state.announcements = payload;
        },

        setCategories (state, payload) {
            state.categories = payload.map(category => {
                return {
                    id: category.id,
                    name: category.title,
                    slug: category.slug,
                    retail_title: category.retail_title,
                    retail_subtitle: category.retail_subtitle,
                    image: category.image ? process.env.VUE_APP_API_WHOLESALE + '/storage/' + category.image : '',
                    isSection: true,
                    subcategories: category.subcategories.map(s => {
                        return {
                            id: s.id,
                            name: s.title,
                            slug: s.slug,
                            image: s.image ? process.env.VUE_APP_API_WHOLESALE + '/storage/' + s.image : '',
                        }
                    })
                }
            });
        },

        setDeliveryTimesCounter (state) {
            let deliveryTimesCounter = cookie.get("delivery_times_modal") || 0;
            deliveryTimesCounter = +deliveryTimesCounter + 1;

            if (deliveryTimesCounter <= process.env.VUE_APP_MAX_SHOW_DELIVERY_TIMES) {
                state.showModalDeliveryTimes = true;
                //show the delivery times modal and set the cookie
                cookie.set("delivery_times_modal", deliveryTimesCounter, -1);
            }
        },

        setShowModalDeliveryTimes (state, payload) {
            state.showModalDeliveryTimes = payload;
        },
    },

    getters: { 
        availableCategoryFilters (state, getters, rootState) {
            let categories = [];

            let categoryFilters = JSON.parse(JSON.stringify(state.categories));

            categoryFilters.forEach(c => {
                const match = rootState.products.all.find(p => {
                    const submatch = c.subcategories.find(sub => p.category_id === sub.id);

                    return (submatch || p.category_id === c.id) && p.active === 1;
                });

                if (match) {
                    c.subcategories.unshift({ id: null, parent_id: null, name: 'All ' + (c.retail_title ?? c.name), slug: c.slug, image: c.image});

                    categories.push(c);
                }
            });

            return categories;
        },
    },

    modules: {
        products,
        user,
        categoryNav
    },
})
