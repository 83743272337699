<template>
    <form action="#" @submit.prevent="handleLogin">
        <alert :message="error" alertStyle="alert-danger" v-if="error" />

        <div class="row">
            <div class="col-md-12">
                <div class="mb-1">
                    <input type="email" v-model="form.email" class="form-control" placeholder="Email" ref="email" />
                    <span v-if="validationErrors.email" class="text-danger">{{ validationErrors.email[0] }}</span>
                </div>

                <div class="mb-1">
                    <input type="password" v-model="form.password" class="form-control" placeholder="Password"/>
                    <span v-if="validationErrors.password" class="text-danger">{{ validationErrors.password[0] }}</span>
                </div>

                <div class="mb-1">
                    <input type="password" v-model="form.password_confirmation" class="form-control" placeholder="Confirm Password"/>
                    <span v-if="validationErrors.password_confirmation" class="text-danger">{{ validationErrors.password_confirmation[0] }}</span>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-4">
                <div class="mb-1">
                    <input type="text" v-model="form.first_name" class="form-control" placeholder="First Name"/>
                    <span v-if="validationErrors.first_name" class="text-danger">{{ validationErrors.first_name[0] }}</span>
                </div>
            </div>

            <div class="col-md-4">
                <div class="mb-1">
                    <input type="text" v-model="form.last_name" class="form-control" placeholder="Last Name"/>
                    <span v-if="validationErrors.last_name" class="text-danger">{{ validationErrors.last_name[0] }}</span>
                </div>
            </div>

            <div class="col-md-4">
                <div class="mb-1">
                    <input type="text" v-model="form.phone" class="form-control" placeholder="Phone" />
                    <span v-if="validationErrors.phone" class="text-danger">{{ validationErrors.phone[0] }}</span>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-8">
                <div class="mb-1">
                    <input type="text" v-model="form.billing_address_1" class="form-control" placeholder="Address" />
                    <span v-if="validationErrors.billing_address_1" class="text-danger">{{ validationErrors.billing_address_1[0] }}</span>
                </div>
            </div>

            <div class="col-md-4">
                <div class="mb-1">
                    <input type="text" v-model="form.billing_address_2" class="form-control" placeholder="Apt, Suite, Etc."/>
                    <span v-if="validationErrors.billing_address_2" class="text-danger">{{ validationErrors.billing_address_2[0] }}</span>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-4">
                <div class="mb-1">
                    <input type="text" v-model="form.billing_city" class="form-control" placeholder="City" />
                    <span v-if="validationErrors.billing_city" class="text-danger">{{ validationErrors.billing_city[0] }}</span>
                </div>
            </div>

            <div class="col-md-4">
                <div class="mb-1">
                    <input type="text" v-model="form.billing_province" class="form-control" placeholder="Province"/>
                    <span v-if="validationErrors.billing_province" class="text-danger">{{ validationErrors.billing_province[0] }}</span>
                </div>
            </div>

            <div class="col-md-4">
                <div class="mb-1">
                    <input type="text" v-model="form.billing_postal" class="form-control" placeholder="Postal" />
                    <span v-if="validationErrors.billing_postal" class="text-danger">{{ validationErrors.billing_postal[0] }}</span>
                </div>
            </div>
        </div>

        <div class="text-right mt-3">
            <input type="submit" class="btn btn-primary rounded-pill" value="Create" />
            <button type="button" @click="$emit('change-form', 'login')" class="btn btn-outline-secondary rounded-pill ml-2">Cancel</button>
        </div>
    </form>
</template>

<script>
import Alert from '@/components/Alert';

export default {
    components: {
        Alert,
    },

    data() {
        return {
            form: {
                email: '',
                password: '',
                password_confirmation: '',
                first_name: '',
                last_name: '',
                billing_address_1: '',
                billing_address_2: '',
                billing_city: '',
                billing_province: '',
                billing_postal: '',
                phone: '',
            },

            error: '',
            validationErrors: '',

            isProcessing: false,
        }
    },

    methods: {
        handleLogin () {
            this.error = '';
            this.validationErrors = '';

            if (this.isProcessing) {
                return;
            }

            this.isProcessing = true;

            this.$store.dispatch('register', this.form)
                .then(() => {
                    this.$emit('change-form', 'registered');
                })
                .catch((error) => {
                    if (error?.response?.status === 422) {
                        this.validationErrors = error.response.data.errors;
                    } else {
                        this.error = 'Something went wrong, please try again';
                    }
                })
                .finally(() => {
                    this.isProcessing = false;
                });
        },
    },

    mounted () {
        this.$refs.email.focus();
    }
}
</script>

<style>

</style>